
import Vue from "vue";

export default Vue.extend({
  name: "Login",
  data: () => ({
    //
  }),
  components: {
    //AppBar,
    // AppFooter,
  },
  mounted() {
    //this.loadingComponentOverlay = false;
  },
});
